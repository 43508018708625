import {BrowserRouter, Route, Routes, Navigate} from "react-router-dom";
import MainPage from "./pages/mainPage";
import FullPattiChart from "./pages/fullChartPatti";
import SchemePage from "./pages/schemePage";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <>
                    <Route path="/" element={<MainPage />} />
                    <Route path="/Live-result" element={<MainPage />} />
                    <Route
                        path="/chart/:drawName/:jodi/:pattern"
                        element={<FullPattiChart />}
                    />
                    <Route
                        path="/scheme"
                        element={<SchemePage />}
                    />
                    <Route path="*" element={<Navigate to="/" replace/>} />
                </>
            </Routes>
        </BrowserRouter>
    );
}


export default App;


